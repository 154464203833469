<template>
  <div :class="containerClass">
    <h1
      :class="h1Class"
      data-custom-brand>
      {{ cleanTitle }}
    </h1>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import UtilsURL from 'CommonUtils/url';

  const props = defineProps({
    title: { type: String, required: true },
    isSearchPage: { type: Boolean, default: false },
    isUniforms: { type: Boolean, default: false },
  });

  const containerClass = computed(() => ({
    h1_seo_title_container: true,
    uniforms_h1_ontainer: props.isUniforms,
  }));

  const h1Class = computed(() => ({
    plp_title_h1: true,
    'uniforms_product-list_title': props.isUniforms,
  }));

  function replaceSearchTermAnalytics(term) {
    if (term && term.indexOf('>') !== -1) return term.slice(0, term.indexOf('>')) + '"';
    return term;
  }
  const cleanTitle = computed(() => {
    const sanitizeUrl = UtilsURL.sanitize(replaceSearchTermAnalytics(props.title));
    if (props.isSearchPage) return props.title;
    return sanitizeUrl;
  });
</script>
